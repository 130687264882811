import React from "react";
import "./footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__container container">
        <p className="footer__title">
          Designed, Developed & Maintained By Palash.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
