import React from "react";
import {
  FiTwitter,
  FiGithub,
  FiLinkedin,
  FiInstagram,
  FiFacebook,
} from "react-icons/fi";

const Social = () => {
  return (
    <div className="home__social">
      <a
        href="https://www.github.com/palashcoder"
        className="home__social-icon"
        rel="noreferrer"
        target="_blank"
      >
        <FiGithub />
      </a>
      <a
        href="https://www.linkedin.com/in/coderpalash"
        className="home__social-icon"
        rel="noreferrer"
        target="_blank"
      >
        <FiLinkedin />
      </a>
      <a
        href="https://twitter.com/coderpalash"
        className="home__social-icon"
        rel="noreferrer"
        target="_blank"
      >
        <FiTwitter />
      </a>
      <a
        href="https://instagram.com/coderpalash"
        className="home__social-icon"
        rel="noreferrer"
        target="_blank"
      >
        <FiInstagram />
      </a>
      <a
        href="https://facebook.com/coderpalash"
        className="home__social-icon"
        rel="noreferrer"
        target="_blank"
      >
        <FiFacebook />
      </a>
    </div>
  );
};

export default Social;
