import React from "react";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import { Link } from "react-scroll";

function Logo() {
  return (
    <>
      <div className="all">
        <Link to="all_p">
          <MdOutlineArrowForwardIos className="all__logo" />
        </Link>
      </div>
    </>
  );
}

export default Logo;
