import img1 from "../../assets/ureckon.JPG"
import img2 from "../../assets/deptproject.jpg"
import img3 from "../../assets/iicmeet.jpg"
import img4 from "../../assets/biggyanmela.jpg"


const data = [
  {
    id: 1,
    img: img1,
    data: "2nd in Ureckon Innovation Challange URECKON, 2022",
  },
  {
    id: 2,
    img: img2,
    data: "2nd in CSE Depertmental Project Competetion, 2022",
  },
  {
    id: 3,
    img: img3,
    data: "2nd in IIC Regional Meet, Kolkata 2024",
  },
  {
    id: 4,
    img: img4,
    data: "2nd in West Bengal Biggyan Mela, 2024",
  },
];

export default data;
