import { CgArrowTopRight } from "react-icons/cg";

const ProjectItems = ({ item }) => {
  const link = item.link;
  return (
    <div className="project__card" key={item.id}>
      <img className="project__img" src={item.image} alt="" />
      <h2 className="project__title">{item.title}</h2>
      <h3 className="tech">{item.tech}</h3>
      <div className="link_dev">
        <a href={link} className="project__button">
          <h4 className="underline">
            Code (GitHub)
            <CgArrowTopRight className="project__button-icon" />
          </h4>
        </a>
        <a href={link} className="project__button">
          <h4 className="underline">
            Demo
            <CgArrowTopRight className="project__button-icon" />
          </h4>
        </a>
      </div>
    </div>
  );
};

export default ProjectItems;
