import "./achievement.css";
import data from "./Data";

function Achievements() {
  return (
    <>
      <section className="achievements">
        <h2 className="section__title">Achievements</h2>
        <span className="section__subtitle">Accomplishments of Mine</span>
        <div className="achievement__card">
          {data.map(({ id, img, data }) => {
            return (
              <div className="container_im" key={id}>
                <img className="project__imgg" src={`${img}`} alt={img} />
                <h2 className="project__title">{data}</h2>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
}

export default Achievements;
