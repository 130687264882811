import React, { useState } from "react";
import "./header.css";
import {
  HiOutlineHome,
  HiOutlineUser,
  HiOutlineBadgeCheck,
  HiOutlineClipboardList,
  HiOutlineMail,
  HiX,
  HiOutlineMenu,
} from "react-icons/hi";
import { GrAchievement } from "react-icons/gr";
import { /* BsPeople,*/ BsHandbag } from "react-icons/bs";
import styled from "styled-components";
import { Link } from "react-scroll";

const StyledText = styled.p`
  font-family: "Dancing Script", cursive;
`;

const Header = () => {
  window.addEventListener("scroll", function () {
    const header = document.querySelector(".header");
    if (this.scrollY >= 80) header.classList.add("scroll-header");
    else header.classList.remove("scroll-header");
  });

  const [Toggle, showMenu] = useState(false);

  return (
    <header className="header">
      <nav className="nav container">
        <StyledText>
          <a href="./" className="nav__logo">
            Palash Das
          </a>
        </StyledText>
        <div className={Toggle ? "nav__menu show-menu" : "nav__menu"}>
          <ul className="nav__list grid active-link">
            <li className="nav__item">
              <Link
                to="home"
                spy={true}
                smooth={true}
                offset={-100}
                duration={100}
                onClick={() => showMenu(false)}
                className="nav__link"
              >
                <HiOutlineHome className="nav__icon" />
                Home
              </Link>
            </li>
            <li className="nav__item">
              <Link
                to="about"
                spy={true}
                smooth={true}
                offset={-40}
                duration={100}
                onClick={() => showMenu(false)}
                className="nav__link"
              >
                <HiOutlineUser className="nav__icon" />
                About
              </Link>
            </li>
            <li className="nav__item">
              <Link
                to="skills"
                spy={true}
                smooth={true}
                offset={10}
                duration={100}
                onClick={() => showMenu(false)}
                className="nav__link"
              >
                <HiOutlineBadgeCheck className="nav__icon" />
                Skills
              </Link>
            </li>
            <li className="nav__item">
              <Link
                to="qualification"
                spy={true}
                smooth={true}
                offset={-40}
                duration={100}
                onClick={() => showMenu(false)}
                className="nav__link"
              >
                <HiOutlineClipboardList className="nav__icon" />
                Qualification
              </Link>
            </li>
            <li className="nav__item">
              <Link
                to="portfolio"
                spy={true}
                smooth={true}
                offset={-10}
                duration={100}
                onClick={() => showMenu(false)}
                className="nav__link"
              >
                <BsHandbag className="nav__icon" />
                Project
              </Link>
            </li>
            {/* <li className="nav__item">
                            <Link to="testimonials" spy={true} smooth={true} offset={-40} duration={100} onClick={() => showMenu(false)}
                                className="nav__link">
                                <BsPeople className="nav__icon" />Testimonial
                            </Link>
                        </li> */}
            <li className="nav__item">
              <Link
                to="achievements"
                spy={true}
                smooth={true}
                offset={-70}
                duration={100}
                onClick={() => showMenu(false)}
                className="nav__link"
              >
                <GrAchievement className="nav__icon" />
                Achievement
              </Link>
            </li>
            <li className="nav__item">
              <Link
                to="contact"
                spy={true}
                smooth={true}
                offset={-40}
                duration={100}
                onClick={() => showMenu(false)}
                className="nav__link"
              >
                <HiOutlineMail className="nav__icon" />
                Contact
              </Link>
            </li>
          </ul>
          <HiX className="nav__close" onClick={() => showMenu(!Toggle)} />
        </div>
        <div className="nav__toggle" onClick={() => showMenu(!Toggle)}>
          <HiOutlineMenu />
        </div>
      </nav>
    </header>
  );
};

export default Header;
