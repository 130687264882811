import Project1 from "../../assets/work1.png";
import Project2 from "../../assets/work2.png";
import Project3 from "../../assets/work3.png";
import Project4 from "../../assets/work4.png";
import Project5 from "../../assets/work5.png";

export const projectsData = [
  {
    id: 1,
    image: Project1,
    title: "Kanban Work Management",
    category: "Fullstack",
    link: "https://github.com/iamrealpalash/kanban-app",
    tech: ["Next.Js,", " AppWrite,", " Zustand,", " TypeScript,", " Tailwind"],
    demo: "",
  },
  {
    id: 2,
    image: Project5,
    title: "Portfolio",
    category: "Frontend",
    link: "https://iamrealpalash.netlify.app",
    tech: ["React.JS,", " Framer-Motion,", " CSS,", " Emailjs"],
    demo: "",
  },
  {
    id: 3,
    image: Project2,
    title: "WebWizardy",
    category: "Frontend",
    link: "https://github.com/iamrealpalash/WebWizardry",
    tech: ["HTML,", " CSS"],
    demo: "",
  },
  {
    id: 4,
    image: Project3,
    title: "News Site",
    category: "Frontend",
    link: "https://github.com/iamrealpalash/basicNewsSite",
    tech: ["HTML,", " CSS,", " JavaScript"],
    demo: "",
  },
  {
    id: 5,
    image: Project4,
    title: "JokesAPI",
    category: "Backend",
    link: "https://github.com/iamrealpalash/jokesAPI",
    tech: ["Node.Js,", " Express.Js,", " REST API"],
    demo: "",
  },
];

export const projectsNav = [
  {
    name: "All",
  },
  {
    name: "Frontend",
  },
  {
    name: "Backend",
  },
  {
    name: "Fullstack",
  },
];
